/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content {
  width: 100%;
  height: 100%;
  background-color: white;
}
.altai-theme-content #bodyView .dossier .btn .icon {
  border: 0px solid #000;
  font-size: 1.5em;
  vertical-align: middle;
}
.altai-theme-content #bodyView h1,
.altai-theme-content #bodyView h2,
.altai-theme-content #bodyView h3,
.altai-theme-content #bodyView h4,
.altai-theme-content #bodyView h5 {
  text-transform: none;
}
.altai-theme-content #bodyView .cktext div,
.altai-theme-content #bodyView p,
.altai-theme-content #bodyView .two-col-introduction div {
  line-height: 1.7em;
  text-align: justify;
  margin-top: 0;
  margin-bottom: 1.625em;
}
.altai-theme-content #bodyView .introrow.smarthpone {
  display: block;
}
.altai-theme-content #bodyView .introrow.screen {
  display: none;
}
.altai-theme-content #bodyView .dossier-contact-section {
  margin-top: 0px;
  margin-bottom: 30px;
  color: #000;
  text-align: center;
}
.altai-theme-content #bodyView .dossier-contact-section span.h6 {
  color: #000 !important;
  text-transform: none;
  font-weight: 600;
}
.altai-theme-content #bodyView .dossier-contact-section .by {
  font-size: 0.8em;
}
@media only screen and (min-width: 768px) {
  .altai-theme-content #bodyView .introrow.smartphone {
    display: none;
  }
  .altai-theme-content #bodyView .introrow.screen {
    display: block;
  }
  .altai-theme-content #bodyView .two-col-introduction {
    padding-right: 40px !important;
    border-right: 5px solid #e1e1e1;
  }
  .altai-theme-content #bodyView .dossier-contact-section {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 0px;
  }
}
.altai-theme-content #bodyView .introrow {
  margin-top: 30px;
}
.altai-theme-content #bodyView .introrow p {
  padding: 0 0 0 0;
}
.altai-theme-content #bodyView .text-center p {
  text-align: center;
}
.altai-theme-content #bodyView .container-content .dossier-tags span.dossier-tags-chip a {
  border: none;
  background-color: none;
}
.altai-theme-content #bodyView .container-content .dossier-tags span.dossier-tags-chip a:hover {
  border: none !important;
  background-color: transparent !important;
  color: white !important;
}
.altai-theme-content #bodyView .container-content .dossier-tags span.dossier-tags-chip:hover a {
  border: none !important;
  background-color: transparent !important;
  color: white;
}
.altai-theme-content .container {
  /*width: 75%;
        margin: auto;*/
  padding-bottom: 20px;
}
.altai-theme-content .dossier-header {
  text-align: center;
}
.altai-theme-content .dossier-name {
  margin-bottom: 20px;
  color: #E34075;
  font-weight: 700;
}
.altai-theme-content h1.dossier-titel {
  text-align: center;
  margin-top: 0;
  font-size: 35px;
  font-weight: 800;
}
.altai-theme-content h2.dossier-titel {
  text-align: center;
  margin-top: 0;
  font-size: 25px;
  font-weight: 800;
}
.altai-theme-content .contact-buttons .mail {
  margin-top: 20px;
  display: inline-block;
  background-color: white;
  color: black;
  padding: 6px;
  font-size: 20px;
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 2px;
}
.altai-theme-content .contact-buttons .like {
  display: none;
}
.altai-theme-content #bodyView .loggedin .contact-buttons .like {
  display: inline-block;
  margin-right: 5px;
}
.altai-theme-content .dossier-contact {
  background-color: #F4024E;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 22px;
  color: white;
  margin: auto;
  border-radius: 10px;
  font-weight: 600;
  display: inline;
  margin-top: 40px;
}
.altai-theme-content .profile-picture img {
  border-radius: 10px;
  width: 50px;
  text-align: center;
}
.altai-theme-content .profile-picture.profile-picture-circle {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 100%;
  display: inline-block;
}
.altai-theme-content .profile-picture.profile-picture-circle img {
  width: 70px;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item {
  /*padding: 0 20px;*/
}
.altai-theme-content .dossier-vragen .dossier-vraag-item.opened {
  padding-bottom: 20px;
  background-color: #fbfbfb;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item.opened i.questionicon {
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}
.altai-theme-content .dossier-vragen .dossier-vraag-item.opened i.copyurl {
  -ms-transform: rotate(0deg);
  /* IE 9 */
  -webkit-transform: rotate(0deg);
  /* Chrome, Safari, Opera */
  transform: rotate(0deg);
}
.altai-theme-content .dossier-vragen .dossier-vraag-item.tables.opened {
  background-color: white;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item .dossier-vraag-item-vraag {
  /*padding: 0 20px;*/
  color: #000;
  text-align: left;
  background-color: #f3f3f3;
  padding: 15px 20px;
  font-weight: 100;
  font-size: 1em;
  width: 100%;
  font-weight: 900;
  margin-bottom: 10px;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item .dossier-vraag-item-vraag .dossier-actual-question.ng-binding {
  display: inline-block;
  width: 80%;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item .dossier-vraag-item-antwoord {
  text-align: justify;
  padding: 10px 20px;
  font-size: 1em;
  width: 100%;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item .dossier-vraag-item-antwoord ul {
  list-style: disc;
  list-style-position: outside;
  list-style-image: none;
  margin-left: 0;
  padding-left: 1em !important;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item .dossier-vraag-item-antwoord li {
  list-style-position: inside;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item.tables .dossier-vraag-item-antwoord {
  /*padding: 0 20px;*/
  text-align: justify;
  padding: 20px 30px;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item i.collapseicon {
  float: right;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item i.questionicon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: white;
  color: black;
  padding: 4px 0 0 3px;
  font-size: 1.2em;
  -webkit-transition-duration: 0.5s;
  /* Safari */
  transition-duration: 0.5s;
}
.altai-theme-content .dossier-vragen .dossier-vraag-item i.copyurl {
  float: right;
  margin-right: 10px;
}
@media (max-width: 400px) {
  .altai-theme-content .dossier-vragen .dossier-vraag-item i.copyurl {
    margin-right: 0;
  }
}
.altai-theme-content .dossier-vragen-yellow .dossier-vraag-item .dossier-vraag-item-vraag {
  background-color: #FFCE00;
}
.altai-theme-content .dark {
  background-color: #C0BEBF;
  color: white;
  font-weight: 600;
}
.altai-theme-content .dossier-chip {
  background-color: #eee;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  padding-bottom: 0px;
  padding-top: 0px;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
}
.altai-theme-content .dossier-chip:not(:first-child) {
  margin-right: 20px;
}
.altai-theme-content #bodyView .dossier-section {
  margin-top: 40px;
  word-wrap: break-word;
}
.altai-theme-content #bodyView .dossier-section section,
.altai-theme-content #bodyView .dossier-section .section {
  padding-top: 0;
}
.altai-theme-content .dossier-vraag-item-vraag {
  -webkit-user-select: none;
  cursor: pointer;
  width: 100%;
  line-height: 1.6;
}
.altai-theme-content .dossier-summary {
  text-align: justify;
}
.altai-theme-content .label .dossier-vraag-item i {
  -webkit-transition: all 0.2s ease;
}
.altai-theme-content .dossier-vraag-item.opened i {
  -webkit-transform: rotate(90deg);
}
.altai-theme-content .dossier-tags {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 2.5em;
  /* text-transform: uppercase; */
  font-size: 0.8em;
}
.altai-theme-content .dossier-tags.center {
  text-align: center;
}
.altai-theme-content .dossier-tags a {
  border: none;
}
.altai-theme-content .dossier-tags a:hover {
  background-color: none;
}
.altai-theme-content .dossier-tags .dossier-tags-chip {
  background-color: #f1f1f1;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 10px;
  cursor: pointer;
  line-height: 1em;
  font-size: 0.9em;
  color: #656565;
}
.altai-theme-content .dossier-tags .dossier-tags-chip.active {
  background-color: #080808;
  color: white;
}
.altai-theme-content .dossier-tags .dossier-tags-chip:hover {
  background-color: #333;
  color: white;
}
.altai-theme-content .dossier-tags .dossier-tags-chip:not(:last-child) {
  margin-right: 10px;
}
@media only screen and (max-width: 768px) {
  .altai-theme-content #bodyView .contentView .container {
    padding-top: 20px;
  }
  .altai-theme-content #bodyView .dossier-tags {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 2em;
    /* text-transform: uppercase; */
    font-size: 0.7em;
  }
  .altai-theme-content #bodyView .dossier-tags.center {
    text-align: center;
  }
  .altai-theme-content #bodyView .dossier-section {
    margin-top: 20px;
  }
  .altai-theme-content #bodyView .event .left-col {
    margin: 0;
  }
}
@media only screen and (min-width: 768px) {
  .altai-theme-content .dossier-tags {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 2.5em;
    /* text-transform: uppercase; */
    font-size: 0.8em;
  }
  .altai-theme-content .dossier-tags.center {
    text-align: center;
  }
}
.altai-theme-content .dossier-tags-xs {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  padding-top: 10px;
  padding-bottom: 0px;
  text-align: center;
  line-height: 2.5em;
  /* text-transform: uppercase; */
  font-size: 0.5em;
}
.altai-theme-content .dossier-tags-xs .dossier-tags-chip {
  background-color: #eee;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 3px;
  padding-top: 3px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 10px;
}
.altai-theme-content .dossier-tags-xs .dossier-tags-chip:hover {
  background-color: #EDEDED;
}
.altai-theme-content .dossier-tags-xs .dossier-tags-chip:not(:first-child) {
  margin-left: 10px;
}
.altai-theme-content .dossier-tags-chip.selected {
  background-color: #666;
  color: white;
}
.altai-theme-content .nomargin {
  margin-bottom: 0 !important;
}
.altai-theme-content .no-animate {
  -webkit-transition: none !important;
  transition: none !important;
}
